/**
 * Add or remove the class to the body
 *
 * @param {bool} fix
 */
// TODO: Consider re-implementing using RXJS and managing the 'body' N  ode?
export default function(fix = true, className = 'is-fixed') {
    const body = document.body;

    // Add or remove the 'is-fixed' class to the body
    if (fix) {
        body.classList.add(className);
    } else {
        body.classList.remove(className);
    }
}
