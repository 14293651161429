import { useEffect, useState } from 'react';

const WINDOWSCROLLNAME = 'scrollPosition';
const DIALOGSCROLLNAME = 'dialogPosition';

/**
 * Listens on scrolling position change.
 Scrolling position stored in session storage.
 Start recording positon using flag. We need to record position when the Grid is finished loading.
 IsDialog flag set to true to use native js to find parent element
 scrollRef is used when we need to use full screen dialog
 Scroll position is cleared when dialog is unmounted
*/
export default function useRestoreScroll({ flag = false, isDialog = false, scrollRef = null }) {
    const storeName = isDialog ? DIALOGSCROLLNAME : WINDOWSCROLLNAME;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [element, setElement] = useState(null);

    useEffect(() => {
        setElement(isDialog ? scrollRef.current : window);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(isDialog ? element.scrollTop : element.scrollY);
        };
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [element]);

    useEffect(() => {
        if (flag) {
            const savedPosition = sessionStorage.getItem(storeName);
            if (savedPosition) {
                element.scrollTo({ top: parseInt(savedPosition), behavior: 'smooth' });
            }
        }
    }, [flag]);

    useEffect(() => {
        if (flag && scrollPosition > 0) {
            sessionStorage.setItem(storeName, scrollPosition);
        }
    }, [scrollPosition]);

    return () => {
        sessionStorage.removeItem(storeName);
    };
}
