import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

class CollapsibleContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = CollapsibleContent.createInitialState();
    }

    static createInitialState() {
        return {
            isActive: false
        };
    }


    // Lifecycle
    /* TODO: Re-instate
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.status.isRouting) {
            // Reset component state
            this.setState(CollapsibleContent.createInitialState());
        }
    }
    */


    // Handler
    handleToggleActive() {
        this.setState({
            isActive: !this.state.isActive
        });
    }


    // Render
    render() {
        const { children, title } = this.props;

        const componentClass = classnames('collapsible-content', {
            'is-active': this.state.isActive
        });

        return (
            <div className={componentClass}>
                <button className="collapsible-content-button" onClick={this.handleToggleActive.bind(this)}>
                    <h6 className="collapsible-content-title">{title}</h6>
                    <Icon name="plus" />
                </button>
                <div className="collapsible-content-wrapper">{children}</div>
            </div>
        );
    }
}

CollapsibleContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ]),
    /*
    status: PropTypes.shape({
        isRouting: PropTypes.bool.isRequired
    }).isRequired,
    */
    title: PropTypes.string.isRequired
};

export default CollapsibleContent;
