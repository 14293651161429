import carouselHOC from 'app/utilities/carousel-single.hoc';
import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

class HeroCarousel extends React.Component {
    render() {
        const {
            activeSlide,
            handleNext,
            handlePrevious,
            items,
            isNext
        } = this.props;

        return (
            <header className="hero-carousel">
                <div className="hero-carousel-inner">
                    <div className="hero-carousel-images">
                        {items.map(({ file }, index) => {
                            const itemClass = classnames('hero-carousel-slide', {
                                'is-active': (activeSlide === index),
                                'is-next': isNext(index)
                            });

                            return (
                                <div className={itemClass} style={{ backgroundImage: `url(${file})` }} key={index}></div>
                            );
                        })}
                    </div>
                    <div className="hero-carousel-intro">
                        <div className="constrain-width large">
                            <h1 className="hero-carousel-lead">Enriching cultural and economic life <br />
                            in Tāmaki Makaurau Auckland</h1>
                            <div className="hero-carousel-caption">
                                {items.map(({ subtitle, link, title }, index) => {
                                    const itemClass = classnames('hero-carousel-caption-item', {
                                        'is-active': (activeSlide === index),
                                        'is-next': isNext(index)
                                    });

                                    return (
                                        <p className={itemClass} key={index}><a href={link}>{title}</a> <br /><em>{subtitle}</em></p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-carousel-actions">
                    <button className="block-button overlay" onClick={handlePrevious.bind(this)}>
                        <span className="block-button-inner">
                            <Icon className="icon reverse" name="arrow-right" title="Previous slide" />
                        </span>
                    </button>
                    <button className="block-button overlay-dark" onClick={handleNext.bind(this)}>
                        <span className="block-button-inner">
                            <span className="block-button-label || hero-carousel-next-action">
                                {items.map(({ title }, index) => {
                                    let isActive = activeSlide === index - 1;

                                    if (activeSlide === items.length - 1 && index === 0) {
                                        isActive = 1;
                                    }

                                    const itemClass = classnames('hero-carousel-next-action-item', {
                                        'is-active': isActive,
                                        'is-next': isNext(index - 1)
                                    });

                                    return (
                                        <span className={itemClass} key={index}>Next: {title}</span>
                                    );
                                })}
                            </span>
                            <Icon name="arrow-right" title="Next slide" />
                        </span>
                    </button>
                </div>
            </header>
        );
    }
}

HeroCarousel.propTypes = {
    activeSlide: PropTypes.number.isRequired,
    handleGoToSlide: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    isNext: PropTypes.func.isRequired
};

export default carouselHOC(HeroCarousel);
