/* eslint-disable camelcase */

import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const BYTE_BASE = 1024;
const DECIMAL_PLACES = 1;
const KILO_BYTE = 1024;
const MEGA_BYTE = KILO_BYTE * BYTE_BASE;
const GIGA_BYTE = MEGA_BYTE * BYTE_BASE;

class FileDownload extends React.Component {
    get fileInfo() {
        const bytes = this.props.filesize;
        const extension = this.props.extension.toUpperCase();
        const extensionText = `/ ${extension}`;

        if ((bytes >= KILO_BYTE) && (bytes < MEGA_BYTE)) {
            return `${(bytes / KILO_BYTE).toFixed(DECIMAL_PLACES)}kb ${extensionText}`;
        } else if ((bytes >= MEGA_BYTE) && (bytes < GIGA_BYTE)) {
            return `${(bytes / MEGA_BYTE).toFixed(DECIMAL_PLACES)}mb ${extensionText}`;
        } else if (bytes > GIGA_BYTE) {
            return `${(bytes / GIGA_BYTE).toFixed(DECIMAL_PLACES)}gb ${extensionText}`;
        }

        return `${bytes} B ${extensionText}`;
    }

    render() {
        const { display_name, file, filename } = this.props;
        const fileInfo = this.fileInfo;

        return (
            <section className="file-download || constrain-width no-pad">
                <div className="file-download-inner || constrain-width">
                    <div className="file-download-details">
                        <span className="file-download-title">{display_name || filename}</span>
                        <span className="file-download-info">{fileInfo}</span>
                    </div>
                    <a
                        className="file-download-link"
                        href={file}
                        download={file}
                        target="_blank"
                        rel="noreferrer">
                        <span className="file-download-link-description">Download</span>
                        <span className="file-download-link-info">{fileInfo}</span>
                        <Icon name="download" />
                    </a>
                </div>
            </section>
        );
    }
}

FileDownload.propTypes = {
    display_name: PropTypes.string,
    extension: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    filesize: PropTypes.string.isRequired
};

export default FileDownload;
