import { createSelector } from 'reselect';
import { getRouteArray } from 'app/utilities/url';

const getPage = (state, url) => {
    const routeArray = getRouteArray(url);

    return state.pages.getIn(routeArray);
};

const pageSelector = createSelector([getPage], (page) => {
    if (page && page.getIn(['data', 0])) {
        return page.getIn(['data', 0]).toJS();
    }

    return null;
});

export default pageSelector;
