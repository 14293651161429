/**
 * Extract a Youtube video id from a video url
 *
 * @param  {string} url Youtube video URL
 *
 * @return {string} Youtube video ID
 */
export const getVideoIdFromUrl = (url) => {
    const match = url.match(/youtu\.be\/(.{11})/) || url.match(/youtube\.com\/watch\?v=(.{11})/);

    if (match) {
        return match[1];
    }

    return null;
};


/**
 * Convert a Youtube video url to an embeddable url for use in iframes
 *
 * @param  {string} url Youtube video URL
 *
 * @return {string} Youtube video embed URL
 */
export const convertUrlToIframeSource = (url) => {
    const id = getVideoIdFromUrl(url);

    return `https://www.youtube.com/embed/${id}?rel=0&controls=1&showinfo=0`;
};

let loadYouTubeIframeAPI = null;

export const loadYouTubeAPI = () => {
    if (!loadYouTubeIframeAPI) {
        loadYouTubeIframeAPI = new Promise((resolve) => {
            if (window.YT && window.YT.Player) {
                resolve(window.YT);
            } else {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                window.onYouTubeIframeAPIReady = () => resolve(window.YT);
            }
        });
    }

    return loadYouTubeIframeAPI;
};

