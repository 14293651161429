/* eslint-disable require-jsdoc */
import { ENDPOINTS } from 'config/api';
import ENQUIRY_FORM_FIELDS from 'config/enquiry-form-fields';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

const RESET_ENQUIRY_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY = 10000; // 10 seconds

export const INITIAL_STATE = {
    fields: ENQUIRY_FORM_FIELDS.reduce((acc, { name, defaultValue }) => {
        acc[name] = {
            value: defaultValue ? defaultValue : '',
            touched: false
        };

        return acc;
    }, {}),
    submitted: false,
    submittedSuccessfully: undefined
};

// Enquiry Form Actions
export const SET_ENQUIRY_FORM_FIELD_VALUE = 'rfa-corporate-website/enquiry/SET_ENQUIRY_FORM_FIELD_VALUE';
export const TOUCHED_ENQUIRY_FORM_FIELD = 'rfa-corporate-website/enquiry/TOUCHED_ENQUIRY_FORM_FIELD';
export const SUBMIT_ENQUIRY_FORM = 'rfa-corporate-website/enquiry/SUBMIT_ENQUIRY_FORM';
export const ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED = 'rfa-corporate-website/enquiry/ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_ENQUIRY_FORM = 'rfa-corporate-website/enquiry/FAILED_TO_SUBMIT_ENQUIRY_FORM';
export const RESET_ENQUIRY_FORM = 'rfa-corporate-website/enquiry/RESET_ENQUIRY_FORM';

// Enquiry Form Action Creators
export const setEnquiryFormFieldValueAction = (fieldName, value) => ({
    type: SET_ENQUIRY_FORM_FIELD_VALUE,
    fieldName,
    value
});

export const touchedEnquiryFormFieldAction = (fieldName) => ({
    type: TOUCHED_ENQUIRY_FORM_FIELD,
    fieldName
});

export const submitEnquiryFormAction = (formData) => ({
    type: SUBMIT_ENQUIRY_FORM,
    formData
});

export const enquiryFormSuccessfullySubmittedAction = () => ({
    type: ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitEnquiryFormAction = () => ({
    type: FAILED_TO_SUBMIT_ENQUIRY_FORM
});

export const resetEnquiryFormAction = () => ({
    type: RESET_ENQUIRY_FORM
});

// Enquiry Form Reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ENQUIRY_FORM_FIELD_VALUE:
            return setEnquiryFormFieldValue(state, action.fieldName, action.value);
        case TOUCHED_ENQUIRY_FORM_FIELD:
            return touchedEnquiryFormField(state, action.fieldName);
        case SUBMIT_ENQUIRY_FORM:
            return submitEnquiryForm(state);
        case ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED:
            return enquiryFormSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_ENQUIRY_FORM:
            return failedToSubmitEnquiryForm(state);
        case RESET_ENQUIRY_FORM:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Enquiry Form Reducer Helpers
function setEnquiryFormFieldValue(state, fieldName, value) {
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                value
            }
        }
    };
}

function touchedEnquiryFormField(state, fieldName) {
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                touched: true
            }
        }
    };
}

function submitEnquiryForm(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function enquiryFormSuccessfullySubmitted(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true
    };
}

function failedToSubmitEnquiryForm(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false
    };
}


// Epic creators

/**
 * @callback enquiryFormSuccessfullySubmittedAction
 * @returns {object} action
 */

/**
 * @callback failedToSubmitEnquiryFormAction
 * @returns {object} action
 */

/**
 * Creates enquiry form submission epic
 * @param {string} enquiryEndpoint - get in touch endpoint
 * @param {enquiryFormSuccessfullySubmittedAction} enquiryFormSuccessfullySubmittedAction - action creator
 * @param {failedToSubmitEnquiryFormAction} failedToSubmitEnquiryFormAction - action creator
 */
export const createEnquiryFormSubmissionEpic = (enquiryEndpoint, enquiryFormSuccessfullySubmittedAction, failedToSubmitEnquiryFormAction) => {
    return (action$) => action$.pipe(
        ofType(SUBMIT_ENQUIRY_FORM),
        mergeMap(({ formData }) => {
            /**
             Uncomment the following return statement to mock successful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(enquiryFormSuccessfullySubmittedAction());
            //     }, 3000);
            // });

            /**
             Uncomment the following return statement to mock unsuccessful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(failedToSubmitEnquiryFormAction());
            //     }, 3000);
            // });

            return (
                fetch(enquiryEndpoint, null, {
                    method: 'POST',
                    body: JSON.stringify(formData)
                })
                    .then(enquiryFormSuccessfullySubmittedAction)
                    .catch(failedToSubmitEnquiryFormAction)
            );
        })
    );
};

export const createScheduleEnquiryFormResettingEpic = (setTimeout, resetEnquiryFormOnSuccessfullSubmissionDelay) => {
    return (action$) => action$.pipe(
        ofType(ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED),
        mergeMap(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(resetEnquiryFormAction());
                }, resetEnquiryFormOnSuccessfullSubmissionDelay);
            });
        })
    );
};

// Epics
const enquiryFormSubmissionEpic = createEnquiryFormSubmissionEpic(
    ENDPOINTS.ENQUIRY,
    enquiryFormSuccessfullySubmittedAction,
    failedToSubmitEnquiryFormAction
);

const scheduleFormResettingEpic = createScheduleEnquiryFormResettingEpic(
    setTimeout,
    RESET_ENQUIRY_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY
);

export const epics = combineEpics(
    enquiryFormSubmissionEpic,
    scheduleFormResettingEpic
);
