import Icon from 'app/components/partials/icon';
import { loadYouTubeAPI } from 'app/utilities/youtube';
import PropTypes from 'prop-types';
import useIsMobile from 'app/hooks/use-is-mobile';
import React, { useEffect, useRef, useState } from 'react';

export const YOUTUBE_STATE = {
    BUFFERING: 3,
    CUED: 5,
    ENDED: 0,
    PAUSED: 2,
    PLAYING: 1,
    UNSTARTED: -1
};

const YoutubePlayer = ({ url, thumbnail, updatePlayerState, autoplay, mute, controls, loop }) => {
    const playerRef = useRef(null);
    const playerContainerRef = useRef(null);

    const isMobile = useIsMobile();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const videoId = url.replace(/.+\/?v(?:=|\/)([^&]+)/gi, '$1');

    const playBtnClickHandler = () => {
        if (playerRef.current) {
            playerRef.current.playVideo();
        }
    };

    // Helpers
    const onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING) {
            setIsVideoPlaying(true);
        } else {
            setIsVideoPlaying(false);
        }

        if (updatePlayerState) {
            updatePlayerState(event.data);
        }
    };

    useEffect(() => {
        let isMounted = true;

        loadYouTubeAPI().then((YT) => {
            if (isMounted && !playerRef.current) {
                playerRef.current = new YT.Player(playerContainerRef.current, {
                    videoId,
                    playerVars: {
                        autoplay: isMobile ? 0 : autoplay,
                        mute,
                        controls,
                        rel: 0,
                        modestbranding: 1,
                        ... loop === 1 ? { loop, playlist: videoId } : {},
                        playsinline: 1
                    },
                    events: {
                        'onStateChange': onPlayerStateChange
                    }
                });
            }
        });

        return () => {
            isMounted = false;
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [videoId, autoplay, mute, controls, loop, isMobile]);

    const isThumbnailImageDisplay = isMobile && !isVideoPlaying;
    const backgroundImage = thumbnail || `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    return (
        <div className="youtube-player">
            <div id="player" className="youtube-player-frame" ref={playerContainerRef}></div>
            {isThumbnailImageDisplay && (
                <div className="thumbnail-image-container" style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                    <Icon name="play-button" onClick={playBtnClickHandler} />
                </div>
            )}
        </div>
    );
};

YoutubePlayer.defaultProps = {
    autoplay: 1,
    mute: 1,
    controls: 1,
    loop: 1
};

YoutubePlayer.propTypes = {
    updatePlayerState: PropTypes.func,
    url: PropTypes.string.isRequired,
    autoplay: PropTypes.number,
    mute: PropTypes.number,
    controls: PropTypes.number,
    loop: PropTypes.number
};

export default YoutubePlayer;
