export const SOCIAL_LINKS = [
    {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/auckland-unlimited'
    },
    {
        name: 'facebook',
        link: 'https://www.facebook.com/aucklandunlimited'
    },
];
