import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import React from 'react';
import truncate from 'app/utilities/truncate';
import useNewsSearch from 'app/hooks/use-news-search';

const DESCRIPTION_CHARACTER_LIMIT = 80;
const SIZE_PER_ROW = 4;

const NewsTile = ({ news }) => {
    const {
        name,
        slug,
        short_description: description,
        thumbnail,
        subtitle
    } = news.attributes;

    return (
        <article className="tile">
            <div className="tile-inner">
                {subtitle && <p className="tile-subtitle || heading-6">{subtitle}</p>}
                <Link to={`/news/${slug}`}>
                    <div className="tile-thumbnail" style={{ backgroundImage: `url(${thumbnail})` }}></div>
                    <h4 className="tile-title">{name}</h4>
                </Link>
                <p className="tile-description">{truncate(description, DESCRIPTION_CHARACTER_LIMIT)}</p>
                <Link to={`/news/${slug}`} className="text-link">
                    {news.type === 'external' ? 'Learn more' : 'Read More'}
                    <Icon name="pointer" width="30" height="20" viewBox="0 0 30 20" />
                </Link>
            </div>
        </article>
    )
}


const LatestNews = ({ title, rows }) => {
    const {
        data,
        page,
        setPage,
        isLoading,
        hasMore,
        error
    } = useNewsSearch(SIZE_PER_ROW * rows);

    const renderViewMoreButton = () => {
        return (
            <button className="button alternative || view-more" onClick={() => setPage(page + 1)} >
                View More
            </button>
        );
    }

    return (
        <div className="tile-block || constrain-width large">
            <h2 className="tile-block-title">Latest News</h2>
            <div className="tile-block-inner">
                {data.map((news, index) => <NewsTile key={index} news={news}></NewsTile>)}
            </div>
            {isLoading && <Loader type="center-loader"/>}
            <div className="tile-block-view-more">
                {!isLoading && hasMore && renderViewMoreButton()}
            </div>
        </div>
    );
};

export default LatestNews;
