import classnames from 'classnames';
import Icon from './icon';
import { NAVIGATION } from 'config/navigation.js';
import { NavLink } from 'react-router-dom'; // eslint-disable-line
import PropTypes from 'prop-types';
import React from 'react';

const MAX_CHILDREN_FOR_ONE_COLUMN = 5;

const Navigation = ({ isActive, itemOpen, toggleActive, updateOpenItem }) => {
    // Handlers
    const handleToggleChildNavigation = (index) => updateOpenItem(itemOpen === index ? null : index);

    // Render
    const renderNavigationItem = ({ children, label, link, teReo }, index) => {
        const itemClass = classnames('navigation-item', {
            'is-active': itemOpen === index
        });

        const subNavigationClass = classnames('sub-navigation', {
            'two-column': children && children.length > MAX_CHILDREN_FOR_ONE_COLUMN
        });

        return (
            <div className={itemClass} key={index}>
                <NavLink
                    className={({ isActive }) => classnames('navigation-link', {
                        'is-active': isActive
                    })}
                    to={link}
                >
                    <span className="te-reo">{teReo}</span>
                    <span className="label">{label}</span>
                </NavLink>
                {children &&
                    <button
                        type="button"
                        className="sub-navigation-toggle"
                        onClick={() => handleToggleChildNavigation(index)}>
                        <Icon name="chevron" title="Open subpages" />
                    </button>}
                {children &&
                    <div className={subNavigationClass}>
                        <nav className="sub-navigation-inner">
                            {children.map(({ label, link }, subIndex) => {
                                // Render Link external links
                                if (link.indexOf('http') > -1) {
                                    return (
                                        <a className="sub-navigation-item" href={link} key={`${index}-${subIndex}`}>{label}</a>
                                    );
                                }

                                return (
                                    <NavLink
                                        className={({ isActive }) => classnames('sub-navigation-item', {
                                            'is-active': isActive
                                        })}
                                        to={link}
                                        key={`${index}-${subIndex}`}
                                        end
                                    >
                                        {label}
                                    </NavLink>
                                );
                            })}
                        </nav>
                    </div>}
            </div>
        );
    };

    const navigationClass = classnames('navigation-bar', {
        'is-active': isActive
    });

    const navigationToggleButton = classnames('navigation-toggle || icon-button', {
        'is-active': isActive
    });

    const hamburgerIcon = classnames('icon-button-icon || hamburger-icon', {
        'is-active': isActive
    });

    return (
        <div className={navigationClass}>
            <div className="navigation-actions">
                <button
                    className={navigationToggleButton}
                    onClick={toggleActive}>
                    <span className="icon-button-inner">
                        <span className={hamburgerIcon}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        <span className="icon-button-label">Menu</span>
                    </span>
                </button>
            </div>
            <div className="navigation-bar-inner">
                <nav className="navigation">
                    {NAVIGATION.map((item, index) => {
                        return renderNavigationItem(item, index);
                    })}
                </nav>
            </div>
        </div>
    );
};

Navigation.propTypes = {
    isActive: PropTypes.bool.isRequired,
    itemOpen: PropTypes.number, // Not required so we can pass null
    toggleActive: PropTypes.func.isRequired,
    updateOpenItem: PropTypes.func.isRequired
};

export default Navigation;
