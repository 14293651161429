import PropTypes from 'prop-types';
import React from 'react';
import YoutubePlayer from './youtube-player';

const HeroVideo = ({ heroVideo, thumbnail }) => {
    return (
        <header className="hero-video">
            <YoutubePlayer url={heroVideo} thumbnail={thumbnail} />
        </header>
    );
};

HeroVideo.propTypes = {
    heroVideo: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired
};

export default HeroVideo;
