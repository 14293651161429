import PropTypes from 'prop-types';
import React from 'react';
import formatContent, { getTextHeading } from 'app/utilities/format-content';

/**
 * Checks whether the content element and aside element have heading elements.
 * If only the content element has a heading, this will return a heading element
 * which should be used inside aside, so both elements may share the same spacing alignment.
 * @param {Object} Object with aside, content and quote data.
 * @returns {String} Heading element (<h1>, <h2>..)
 */
function getContentHeadingElement({ aside, content, quote }) {
    const contentHeading = getTextHeading(content);
    const asideHeading = aside ? getTextHeading(aside) : getTextHeading(quote);

    if (contentHeading && !asideHeading) {
        return contentHeading;
    }

    return '';
}

function TextContent(props) { // eslint-disable-line require-jsdoc
    const { aside, citation, content, quote } = props.data;

    if (aside || quote) {
        const headingElement = getContentHeadingElement(props.data);

        return (
            <section className="text-content-block">
                <div className="text-content-block-inner">
                    <div className="text-content-block-main">
                        <div
                            className="constrain-width content || rte-content || rich-editor-content"
                            dangerouslySetInnerHTML={{ __html: formatContent(content) }}>
                        </div>
                    </div>
                    {aside &&
                        <div className="text-content-block-aside">
                            <div
                                className="constrain-width content || rte-content || rich-editor-content"
                                dangerouslySetInnerHTML={{ __html: `${headingElement ? headingElement : ''} ${formatContent(aside)}` }}>
                            </div>
                        </div>
                    }
                    {quote &&
                        <div className="text-content-block-aside">
                            <div className="constrain-width content">
                                <blockquote>
                                    <p className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(quote) }}></p>
                                    <em>{citation}</em>
                                </blockquote>
                            </div>
                        </div>
                    }
                </div>
            </section>
        );
    }

    return (
        <section className="text-content-block">
            <div
                className="text-content-block-single || constrain-width content || rte-content || rich-editor-content"
                dangerouslySetInnerHTML={{ __html: formatContent(content) }}>
            </div>
        </section>
    );
}

TextContent.propTypes = {
    data: PropTypes.object.isRequired
};

export default TextContent;
