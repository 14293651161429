import { createValidationDataObject } from 'app/utilities/form-validation';
import fieldTypes from './field-types';

export default [
    {
        name: 'name',
        label: '',
        placeholder: 'Name*',
        required: true,
        type: 'text',
        fieldType: fieldTypes.INPUT,
        validator: (name) => {
            if (name) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'email',
        label: '',
        placeholder: 'Email*',
        required: true,
        type: 'email',
        fieldType: fieldTypes.INPUT,
        validator: (email) => {
            if (email) {
                // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
                if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) { // eslint-disable-line
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'phone',
        label: '',
        placeholder: 'Phone',
        required: false,
        type: 'text',
        fieldType: fieldTypes.INPUT,
        validator: () => createValidationDataObject()
    },
    {
        name: 'message',
        label: '',
        placeholder: 'Message*',
        required: true,
        fieldType: fieldTypes.TEXTAREA,
        validator: (message) => {
            if (message) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    }
];
