/**
 * Format the WYSIWYG content
 *
 * @param  {string} content WYSIWYG content
 * @param  {string} type component type (currently only used for call-to-action component)
 * @param  {string} buttonClass custom button class
 *
 * @return {string} formatted content
 */
export default function(content, type = null, buttonClass = 'button primary') {
    // Change the button class
    const formattedContent = content.replace(
        /class="small primary button"/g,
        `class="${buttonClass}"`
    ).replace(
        /<h1>/g,
        getHeadingElement(type)
    ).replace(
        /<(\/)h1>/g,
        '</h2>'
    );

    return formattedContent;
}

/**
 * Checks whether the content contains a heading (h1, h2..) as the first element
 * and returns the same heading element without aside-title class, which should be
 * used to add extra space on text aside when this does not have a heading (alignment).
 * @param {String} content - RTE content from CMS.
 * @returns {String} Heading element (<h1 class="aside-title" aria-hidden="true"></h1>)
 */
export function getTextHeading(content) { // eslint-disable-line require-jsdoc
    if ((/^(<h1>)/).test(content)) {
        return '<h1 class="aside-title" aria-hidden="true"></h1>';
    } else if ((/^(<h2>)/).test(content)) {
        return '<h2 class="aside-title" aria-hidden="true"></h2>';
    } else if ((/^(<h3>)/).test(content)) {
        return '<h3 class="aside-title" aria-hidden="true"></h3>';
    } else if ((/^(<h4>)/).test(content)) {
        return '<h4 class="aside-title" aria-hidden="true"></h4>';
    } else if ((/^(<h5>)/).test(content)) {
        return '<h5 class="aside-title" aria-hidden="true"></h5>';
    } else if ((/^(<h6>)/).test(content)) {
        return '<h6 class="aside-title" aria-hidden="true"></h6>';
    }

    return '';
}

/**
 * Currently it returns the H2 with specific heading styles for the call-to-action component.
 * @param {string} type - The component type
 */
function getHeadingElement(type) {
    return type === 'call-to-action' ? '<h2 class="heading-2">' : '<h2 class="heading-1">';
}
