import PropTypes from 'prop-types';
import React from 'react';


function PageHeader({ images, introduction, title }) {
    // Use the first image only as the background
    const image = images && images.length ? images[0] : null;
    const style = image ? { backgroundImage: `url(${image})` } : {};

    return (
        <header
            role="heading"
            className={`page-header ${!images || images.length === 0 ? '|| has-no-image' : '|| image-overlay'}`}
            style={style}
        >
            <div className="page-header-wrapper || constrain-width">
                <div className="page-header-inner">
                    <h1 className="page-header-title">{title}</h1>
                    {introduction && <p className="page-header-introduction">{introduction}</p>}
                </div>
            </div>
        </header>
    );
}

PageHeader.propTypes = {
    images: PropTypes.array,
    introduction: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default PageHeader;
