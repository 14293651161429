import carouselSingleHOC from 'app/utilities/carousel-single.hoc';
import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import getThumbnailUrl from 'app/utilities/get-thumbnail-url';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

class ImageGallerySingle extends React.Component {
    renderNavigation() {
        const {
            activeSlide,
            handlePrevious,
            handleNext,
            handleGoToSlide,
            items,
            isNext
        } = this.props;

        // Only render pagination if there is more than one image
        if (items.length < 2) return null;

        return (
            <nav className="carousel-navigation-actions">
                {/* Previous */}
                <button type="button" className="block-button overlay-dark" onClick={handlePrevious.bind(this)}>
                    <span className="block-button-inner">
                        <Icon className="icon reverse" name="arrow-right" title="Previous slide" />
                    </span>
                </button>

                {/* Pagination */}
                <div className="carousel-pagination">
                    {items.map(({ title }, index) => {
                        const itemClass = classnames('carousel-pagination-item', {
                            'is-active': activeSlide === index,
                            'is-next': isNext(index)
                        });

                        return (
                            <button
                                className={itemClass}
                                title={title}
                                onClick={handleGoToSlide.bind(this, index)}
                                key={index}>
                                <span className="carousel-pagination-item-title">{`Go to slide ${index}`}</span>
                            </button>
                        );
                    })}
                </div>

                {/* Next */}
                <button type="button" className="block-button overlay-dark" onClick={handleNext.bind(this)}>
                    <span className="block-button-inner">
                        <Icon className="icon" name="arrow-right" title="Next slide" />
                    </span>
                </button>
            </nav>
        );
    }

    render() {
        const { activeSlide, items, isNext, pageTitle } = this.props;

        return (
            <section className="image-gallery-single">
                {/* Carousel */}
                <div className="carousel">
                    {/* Images */}
                    <div className="carousel-inner || preserve-aspect-ratio standard" role="group">
                        {items.map(({ file, file_alt: fileAlt }, index) => {
                            const itemClass = classnames('carousel-media', {
                                'is-active': activeSlide === index,
                                'is-next': isNext(index)
                            });

                            return (
                                <div className={itemClass} key={index}>
                                    <div className="carousel-image || preserve-aspect-ratio-media || image-overlay" style={{ backgroundImage: `url(${file})` }}>
                                        <img className="fixed-image" src={file} alt={fileAlt || pageTitle} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {this.renderNavigation()}
                </div>

                {/* Carousel Navigation */}
                <div className="carousel-navigation">
                    {/* Background */}
                    <div className="carousel-navigation-background" aria-hidden="true">
                        {items.map(({ file }, index) => {
                            const itemClass = classnames('carousel-navigation-background-item', {
                                'is-active': activeSlide === index,
                                'is-next': isNext(index)
                            });

                            return (
                                <div className={itemClass} style={{ backgroundImage: `url(${getThumbnailUrl(file)})` }} key={index}></div>
                            );
                        })}
                    </div>

                    {/* Content */}
                    <div className="carousel-navigation-content">
                        <div className="constrain-width">
                            <div className="carousel-navigation-creditline">
                                {items.map(({ creditline }, index) => {
                                    const itemClass = classnames('carousel-navigation-creditline-item || rich-editor-content', {
                                        'is-active': activeSlide === index,
                                        'is-next': isNext(index)
                                    });

                                    return (
                                        <div
                                            className={itemClass}
                                            dangerouslySetInnerHTML={{ __html: formatContent(creditline) }}
                                            key={index}
                                        ></div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

ImageGallerySingle.propTypes = {
    activeSlide: PropTypes.number.isRequired,
    handleGoToSlide: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    isNext: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default carouselSingleHOC(ImageGallerySingle);
