import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { useEffect, useRef, useState } from 'react';

const useNewsSearch = (pageSize) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [error, setError] = useState(null);
    const controllerRef = useRef();

    const fetchData = async() => {
        setIsLoading(true);
        setError(null);
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        try {
            const results = await fetch(`${ENDPOINTS.NEWS(false, page, pageSize)}`, null, {
                signal: controllerRef.current.signal,
                method: 'GET'
            });
            const { data: data, meta: meta } = results;
            if (page === 1) {
                setData(data);
            } else {
                setData((preValue) => [...preValue, ...data]);
            }
            setHasMore(page < Math.ceil(meta.result_count / pageSize));
        } catch (error) {
            setError(error);
        } finally {
            controllerRef.current = null;
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page]);

    return {
        data,
        page,
        setPage,
        isLoading,
        hasMore,
        error
    };
};

export default useNewsSearch;
