import classnames from 'classnames';
import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import truncate from 'app/utilities/truncate';
import React, { useState } from 'react';

const DESCRIPTION_CHARACTER_LIMIT = 80;

function renderTile(item, index) { // eslint-disable-line require-jsdoc
    const {
        name,
        short_description: description,
        thumbnail,
        subtitle
    } = item.data;

    let style = {};

    if (thumbnail) {
        style = { backgroundImage: `url(${thumbnail})` };
    }

    const componentClass = classnames('tile', {
        'has-subtitle': subtitle
    });

    // corporate external link, add if statement since this is only used in corporate website
    // TODO: change rfa-react-core EntityLink if external link need to use in other BUs
    const renderLink = (item, className, children) => {
        return item.type === 'external' ? (
            <a href={item.url} target="_blank" rel="noreferrer" className={className}>
                {children}
            </a>
        ) : (
            <EntityLink
                item={item}
                promoterBuName="RFA Corporate"
                className={className}>
                {children}
            </EntityLink>
        );
    };

    return (
        <article className={componentClass} key={index}>
            <div className="tile-inner">
                {subtitle && <p className="tile-subtitle || heading-6">{subtitle}</p>}
                {renderLink(item, null, (
                    <>
                        <div className="tile-thumbnail" style={style}></div>
                        <h4 className="tile-title">{name}</h4>
                    </>
                ))}
                <p className="tile-description">{truncate(description, DESCRIPTION_CHARACTER_LIMIT)}</p>
                {renderLink(item, 'text-link', (
                    <>
                        {item.type === 'external' ? 'Learn more' : 'Read More'}
                        <Icon name="pointer" width="30" height="20" viewBox="0 0 30 20" />
                    </>
                ))}
            </div>
        </article>
    );
}

function ContentSet({ items, title, rows }) { // eslint-disable-line require-jsdoc
    const [displayRows, setDisplayRows] = useState(parseInt(rows) || 1);
    // View more button will show 2 more rows each time it's clicked until all rows are shown
    const viewMoreBtnClickHandler = () => setDisplayRows((oldRows) => oldRows + 2);
    // View less button will hide all rows except for initial number of rows
    const viewLessBtnClickHandler = () => setDisplayRows(parseInt(rows) || 1);

    const renderGrid = () => {
        const itemsPerLine = 4;
        const shouldDisplayViewMoreToggle = rows * itemsPerLine < items.length;

        const displayCount = Math.min(displayRows * itemsPerLine, items.length);
        const displayItems = items.slice(0, displayCount);
        const showViewMore = displayCount < items.length;

        return (
            <>
                <div className="tile-block-inner">
                    {displayItems.map(renderTile)}
                </div>
                {shouldDisplayViewMoreToggle &&
                <div className="tile-block-view-more">
                    {showViewMore &&
                        <button
                            className="button alternative || view-more"
                            onClick={viewMoreBtnClickHandler}
                        >View more</button>
                    }
                    {!showViewMore &&
                        <button
                            className="button alternative || view-more"
                            onClick={viewLessBtnClickHandler}
                        >View less</button>
                    }
                </div>
                }
            </>
        );
    };

    return (
        <section className="content-set">
            <div className="tile-block || constrain-width large">
                {title && <h2 className="tile-block-title">{title}</h2>}
                {renderGrid()}
            </div>
        </section>
    );
}

ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    rows: PropTypes.string
};

export default ContentSet;
