import Data from 'app/components/partials/data';
import PropTypes from 'prop-types';
import React from 'react';

function Pricing(props) {
    const { data: plans } = props;

    return (
        <Data title="Content Block: Pricing" data={{ plans }} />
    );
}

Pricing.propTypes = {
    data: PropTypes.array.isRequired
};

export default Pricing;
