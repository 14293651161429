import PropTypes from 'prop-types';
import React from 'react';

function Button({ title, url }) { // eslint-disable-line
    return (
        <section className="button-block">
            <div className="button-block-inner || constrain-width large">
                <a className="button primary large" href={url}>
                    <div className="button-inner">{title}</div>
                </a>
            </div>
        </section>
    );
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Button;
