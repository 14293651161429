import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

const BUTTON_CLASS = 'button small';

class FlexiColumn extends React.Component {
    render() {
        const { title, content, button, file, thumbnail } = this.props;

        const backgroundImage = thumbnail && thumbnail.file ? thumbnail.file : '';

        return (
            <div className="flexi-item">
                <div className="media" style={{ backgroundImage: `url(${file})` }}>
                    <div className="preserve-image-ratio">
                        <img className="fixed-image" src={file} alt={file} />
                    </div>
                </div>
                <div className="title-group" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    {Boolean(title) && <h2 className="title-group-heading">{title}</h2>}
                    {Boolean(content) && (
                        <div className="title-group-content" dangerouslySetInnerHTML={{ __html: formatContent(content, BUTTON_CLASS) }}></div>
                    )}
                    {(button && button.href && button.label) && (
                        <div className="title-group-button-wrapper">
                            <a className="button primary" href={button.href}>{button.label}</a>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

FlexiColumn.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
    }),
    file: PropTypes.string.isRequired,
    thumbnail: PropTypes.object
};


class Flexi extends React.Component {
    render() {
        const { items } = this.props;

        return (
            <section className="flexi-block">
                <div className="constrain-width large text-content-block-single">
                    <div className="inner">
                        {items.map((item, index) => <FlexiColumn key={index} {...item} />)}
                    </div>
                </div>
            </section>
        );
    }
}

Flexi.propTypes = {
    items: PropTypes.array.isRequired
};

export default Flexi;
