import Error from './error/error-page';
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import NotFound from './error/not-found-page';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import Template from 'app/components/templates/index';
import templateType from '../../utilities/template-type';

const ContentPage = ({ page }) => {
    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR) return <Error />;
    if (page.error && page.error.status === RESPONSE.NOT_FOUND || !page.attributes) return <NotFound />;

    const {
        is_published: isPublished,
        name: title,
        main_images: mainImages,
        seo_description: seoDescription,
        seo_keywords: seoKeywords,
        slug,
        thumbnail
    } = page.attributes;

    const image = mainImages && mainImages[0] || thumbnail;
    const isNoIndex = !isAppProd || !isPublished;
    const templateTypeToUse = templateType(slug, page.type);

    return (
        <main className="page" role="main">
            <Helmet>
                {isNoIndex && <meta name="robots" content="noindex, nofollow" />}

                <title>{title}</title>
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />

                {/* Open Graph */}
                <meta property="og:locale" content="en_NZ" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={seoDescription} />
                <meta property="og:image" content={image} />
            </Helmet>
            <Template
                type={templateTypeToUse}
                data={page.attributes}
            />
        </main>
    );
};

ContentPage.propTypes = {
    page: PropTypes.object.isRequired
};

export default ContentPage;
