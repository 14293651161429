/* eslint-disable camelcase */
import AUCKLAND_UNLIMITED_LOGO from 'static/images/logos/tataki-auckland-unlimited-a-navy-rgb.svg';
import classnames from 'classnames';
import { hideNotification } from 'app/utilities/notifications';
import Icon from 'app/components/partials/icon';
import Navigation from 'app/components/partials/navigation';
import NotificationBanner from 'app/components/partials/notification-banner.container';
import PropTypes from 'prop-types';
import SearchDialog from './search-dialog';
import updateBody from 'app/utilities/update-body';
import { useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const Header = ({ fetchNotifications, notifications, isNotificationsActive, toggleNotifications }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isNavigationActive, setIsNavigationActive] = useState(false);
    const [navigationItemOpen, setNavigationItemOpen] = useState(null);
    const searchDialogOpen = searchParams.get('search') === 'true';
    const location = useLocation();
    const status = useSelector((state) => state.status);

    useEffect(() => {
        fetchNotifications();
    }, []);

    // location change fold menu
    useEffect(() => {
        if (!status.isRouting) {
            handleCloseNavigation();
        }
    }, [location.pathname]);

    // disable scroll if search dialog is open
    useEffect(() => {
        updateBody(searchDialogOpen);
    }, [searchParams]);

    // Handler
    const handleToggleNavigation = () => {
        // On close reset child navigation items
        if (isNavigationActive) {
            setNavigationItemOpen(null);
        }

        setIsNavigationActive(!isNavigationActive);
        // disable scroll when mobile nav is open
        updateBody(!isNavigationActive);

        if (isNotificationsActive && !isNavigationActive) {
            this.handleToggleNotifications();
        }
    };

    const handleUpdateNavigationItem = (index = null) => {
        setNavigationItemOpen(index);
    };

    const handleCloseNavigation = () => {
        setIsNavigationActive(false);
        setNavigationItemOpen(null);
        // enable scroll when mobile nav is closed but only if search dialog is not open
        updateBody(searchDialogOpen);
    };

    const handleToggleNotifications = (closeNavigation) => {
        hideNotification('all');

        // Mobile navigation must be closed if notifications are open.
        if (closeNavigation && isNavigationActive) this.handleCloseNavigation();

        toggleNotifications();
    };

    const baseClass = 'header';

    const notificationsButtonClass = classnames('notifications-button', {
        'is-active': !isNotificationsActive
    });

    const handleToggleSearchDialog = () => {
        if (searchDialogOpen) {
            searchParams.delete('search');
            searchParams.delete('keyword');
            searchParams.delete('modalPage');
        } else {
            searchParams.set('search', true);
        }
        setSearchParams(searchParams);
    };

    return (
        <>
            <header className={baseClass}>
                <NotificationBanner
                    notifications={notifications}
                />
                <div className={`${baseClass}-wrapper`}>
                    <div className={`${baseClass}-inner || constrain-width x-large`}>
                        <div className={`${baseClass}-actions`}>
                            <button className="search-icon" onClick={handleToggleSearchDialog}>
                                <Icon name="search" width="25" height="25" />
                            </button>
                            {notifications.length > 0 &&
                                <button className={notificationsButtonClass} onClick={handleToggleNotifications}>
                                    <Icon name="bell" />
                                </button>
                            }
                            <Link className={`${baseClass}-logo`} to="/">
                                <img src={AUCKLAND_UNLIMITED_LOGO} alt="Tātaki Auckland Unlimited" />
                            </Link>
                        </div>
                        <Navigation
                            isActive={isNavigationActive}
                            itemOpen={navigationItemOpen}
                            toggleActive={handleToggleNavigation}
                            updateOpenItem={handleUpdateNavigationItem}
                        />
                    </div>
                </div>
            </header>
            {searchDialogOpen && <SearchDialog toggleSearchDialog={handleToggleSearchDialog} />}
        </>
    );
    // }
};

Header.propTypes = {
    fetchNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    isNotificationsActive: PropTypes.bool.isRequired,
    status: PropTypes.shape({
        isRouting: PropTypes.bool.isRequired
    }).isRequired,
    toggleNotifications: PropTypes.func.isRequired,
};

export default Header;
