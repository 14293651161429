import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import customUrls from './custom-urls';
import status from './status';
import enquiryForm, { epics as enquiryFormEpics } from './enquiry-form';
import notifications, { epics as notificationsEpic } from './notifications';
import pages, { epics as pageEpics } from './page';

export const epics = combineEpics(
    enquiryFormEpics,
    notificationsEpic,
    pageEpics
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    enquiryForm,
    notifications,
    pages,
    status,
    customUrls,
});

export default createRootReducer;

