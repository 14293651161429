import debounce from 'debounce-promise';
import fetch from 'app/utilities/fetch';
import { formatSearchResults } from 'app/utilities/format-data';
import FullScreenDialog from './full-screen-dialog';
import Icon from './icon';
import Loader from './loader';
import Paginator from './paginator';
import PropTypes from 'prop-types';
import SearchGrids from './search-grids';
import useRestoreScroll from '../../hooks/use-restore-scroll';
import { useSearchParams } from 'react-router-dom';
import { ENDPOINTS, RESPONSE } from 'config/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const SearchDialog = ({ toggleSearchDialog }) => {
    const dialogRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const [currentPage, setCurrentPage] = useState(searchParams.get('modalPage') || 1);
    const [searchText, setSearchText] = useState(searchParams.get('keyword') || '');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchResponse, setSearchResponse] = useState({
        results: [],
        links: {},
        count: null
    });

    const resetSearchResults = () => {
        setResults(null, {}, []);
        setError(false);
    };

    const setResults = (count, links, results, page = 1) => {
        setSearchResponse({
            count,
            links,
            results
        });
        setCurrentPage(page);
    };

    const searchTextChangeHandler = (event) => {
        setSearchText(event.target.value);
        resetSearchResults();
        syncUrlSearchParams(event.target.value);
        debouncedSearchResultsAction(event.target.value, 1);
    };

    const searchResults = async(keyword, page = 1) => {
        if (!keyword) {
            return;
        }
        setIsLoading(true);
        try {
            const fetchArray = [...Array(parseInt(page)).keys()];
            const response = await Promise.all(fetchArray.map((item) => fetch(`${ENDPOINTS.SEARCH}?keywords=${encodeURI(keyword)}&page=${item + 1}`)));
            const { count, links, results } = formatSearchResults(response);
            setResults(count, links, results, page);
        } catch (err) {
            if (err.status === RESPONSE.NOT_FOUND) {
                setResults(0, {}, []);
            } else {
                setError(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedSearchResultsAction = useCallback(
        // eslint-disable-next-line no-magic-numbers
        debounce((keyword, page) => searchResults(keyword, page), 1000), []
    );

    const closeDialog = () => {
        toggleSearchDialog();
        clearScrollPos();
    };

    useEffect(() => {
        if (searchText) {
            searchResults(searchText, currentPage);
        }
    }, []);

    const syncUrlSearchParams = (searchText, currentPage = 1) => {
        if (searchText) {
            searchParams.set('keyword', searchText);

            if (currentPage && currentPage > 1) {
                searchParams.set('modalPage', currentPage);
            } else {
                searchParams.delete('modalPage');
            }
        } else {
            searchParams.delete('keyword');
            searchParams.delete('modalPage');
        }
        setSearchParams(searchParams);
    };

    const clearScrollPos = useRestoreScroll({
        flag: searchResponse.count > 0,
        isDialog: true,
        scrollRef: dialogRef,
    });

    const paginatorClickHandler = async() => {
        try {
            const page = parseInt(currentPage) + 1;
            const response = await fetch(`${ENDPOINTS.SEARCH}?keywords=${encodeURI(searchText)}&page=${page}`);
            const { links, results } = formatSearchResults([response]);
            setResults(searchResponse.count, links, [...searchResponse.results, ...results], page);
            syncUrlSearchParams(searchText, page);
        } catch (err) {
            setError(true);
        }
    };

    const renderResults = () => {
        if (error) {
            return (<p>Oops, looks like something went wrong. <br/>Please try your search again.</p>);
        }

        if (!searchResponse.count || searchResponse.count === 0) {
            return (
                <>
                    <p>Your search has no results – but we still have lots to show you!<br/>Try using a different search keyword.</p>
                </>
            );
        }


        return (
            <>
                <p>We found {searchResponse.count} results.</p>
                <Paginator currentItemsLength={searchResponse.results.length} total={searchResponse.count} btnClickHandler={paginatorClickHandler}>
                    <SearchGrids items={searchResponse.results} />
                </Paginator>
            </>
        );
    };

    return (
        <FullScreenDialog extraClass="search-dialog" restoreScroll={searchResponse.count > 0} ref={dialogRef}>
            <button className="no-border close-button" onClick={closeDialog}>
                <Icon className="icon" name="close" width="30" height="30"/>
            </button>
            <form className="form search-dialog-form" onSubmit={(event) => event.preventDefault()}>
                <h5 className="heading">Type in your search and press enter</h5>
                <input
                    className="input"
                    name="query"
                    type="text"
                    autoComplete="off"
                    value={searchText}
                    onChange={searchTextChangeHandler}
                    autoFocus
                />
            </form>
            {searchText && (
                <div className="search-results-wrapper">
                    {isLoading && <Loader type="center-loader"/>}
                    {searchResponse.count !== null && renderResults()}
                </div>
            )}
        </FullScreenDialog>
    );
};

SearchDialog.propTypes = {
    toggleSearchDialog: PropTypes.func.isRequired
};

export default SearchDialog;
