/* eslint-disable camelcase, quotes */

export const VENUES = [
    {
        label: "Aotea Centre",
        link: '/our-venues/aotea-centre'
    },
    {
        label: "Aotea Square",
        link: '/our-venues/aotea-square'
    },
    {
        label: "Auckland Art Gallery",
        link: '/our-venues/auckland-art-gallery'
    },
    {
        label: "Auckland Town Hall",
        link: '/our-venues/auckland-town-hall'
    },
    {
        label: "Auckland Zoo",
        link: '/our-venues/auckland-zoo'
    },
    {
        label: "Bruce Mason Centre",
        link: '/our-venues/bruce-mason-centre'
    },
    {
        label: "The Civic",
        link: '/our-venues/the-civic'
    },
    {
        label: "Mt Smart Stadium",
        link: '/our-venues/mt-smart-stadium'
    },
    {
        label: "NZ Maritime Museum",
        link: '/our-venues/nz-maritime-museum'
    },
    {
        label: "North Harbour Stadium",
        link: '/our-venues/north-harbour-stadium'
    },
    {
        label: "Queens Wharf",
        link: '/our-venues/queens-wharf'
    },
    {
        label: "Western Springs Stadium",
        link: '/our-venues/western-springs-stadium'
    }
];

const publicationsItem = {
    label: 'Publications & Public Information',
    link: '/about-us/publications-public-information'
};

export const NAVIGATION = [
    {
        label: 'About Us',
        link: '/about-us',
        teReo: 'Ō Mātou Kōrero',
        children: [
            {
                label: 'News',
                link: '/about-us/news'
            },
            {
                label: 'Our People',
                link: '/about-us/our-people'
            },
            publicationsItem,
            {
                label: 'Careers',
                link: '/about-us/careers'
            }
        ]
    },
    {
        label: "What We Do",
        link: '/what-we-do',
        teReo: 'Ratonga',
        children: [
            {
                label: 'Statement of Intent',
                link: '/what-we-do/statement-of-intent'
            },
            {
                label: 'Projects and initiatives',
                link: '/what-we-do/projects-initiatives'
            }
        ]
    },
    {
        label: 'What\'s On',
        link: '/whats-on',
        teReo: 'Kaupapa Whakatairanga'
    },
    {
        label: 'Contact Us',
        link: '/contact-us',
        teReo: 'Whakapā Mai'
    }
];
