import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ROW_TO_ADD_PER_CLICK = 8;

const Table = ({ title, cols, rows }) => {
    const totalRowCount = rows ? rows.length : 0;
    const initialTableRowCount = Math.min(ROW_TO_ADD_PER_CLICK, totalRowCount);
    const [displayTableRowCount, setDisplayTableRowCount] = useState(initialTableRowCount);

    const isDisplayViewMoreBtn = displayTableRowCount < totalRowCount;
    const isDisplayShowLessBtn = !isDisplayViewMoreBtn && totalRowCount > initialTableRowCount;

    return (
        <section className="table-block">
            <div className="table-block-inner || constrain-width large">
                {title && <h2 className="heading-2">{title}</h2>}
                <div className="table-container">
                    <table className="custom-table">
                        <thead>
                            {cols && cols.map((column, index) => (
                                <th key={index}>
                                    <p>{column}</p>
                                </th>
                            ))}
                        </thead>
                        <tbody>
                            {rows && rows.map((row, rowIndex) => {
                                if (rowIndex + 1 <= displayTableRowCount) {
                                    return (
                                        <tr key={rowIndex}>
                                            {row && row.map((cell, cellIndex) => (
                                                <td key={cellIndex}>
                                                    <p>{cell}</p>
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                </div>
                {isDisplayViewMoreBtn && <button key="button" className="button primary" onClick={() => setDisplayTableRowCount(displayTableRowCount + ROW_TO_ADD_PER_CLICK)}>View more</button>}
                {isDisplayShowLessBtn && <button key="button" className="button primary" onClick={() => setDisplayTableRowCount(initialTableRowCount)}>Show less</button>}
            </div>
        </section>
    );
};

Table.propTypes = {
    title: PropTypes.string,
    cols: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string)
    )
};

export default Table;
