import { connect } from 'react-redux';
import NotificationBanner from 'app/components/partials/notification-banner';
import {
    selectIsActive,
    selectShowNotification
} from 'app/selectors/notifications';
import {
    toggleNotificationsAction,
    updateNotificationAction
} from 'app/ducks/notifications';

const mapStateToProps = (state) => ({
    isActive: selectIsActive(state),
    showNotification: selectShowNotification(state)
});

const mapDispatchToProps = (dispatch) => ({
    toggleNotifications: () => dispatch(toggleNotificationsAction()),
    updateNotification: (notificationId) => dispatch(updateNotificationAction(notificationId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationBanner);
