/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * Higher order function that wraps a component with the methods
 * needed when implementing the carousel functionality.
 *
 * @param  {ReactComponent} WrappedComponent – Takes a dumb carousel component
 * @return {ReactComponent}                  – Returns a React component with methods passed as props
 */
export default function(WrappedComponent) { // eslint-disable-line
    class WrapperComponent extends Component {
        constructor(props) {
            super(props);

            this.state = WrapperComponent.createInitialState();

            this.autoRotate = null;
        }

        static createInitialState() {
            return {
                activeSlide: 0,
                autoRotate: true,
                nextReady: true
            };
        }

        /**
         * Takes the items prop and lays up two items per slide so the slide
         * index and count is different to the sinlge caousel
         *
         * @return {[array]} – Two items per slide
         */
        get slides() {
            const { items } = this.props;

            // Reformat items into slides of two
            const slides = [];
            let slideIndex = 0;

            items.map((item, index) => {
                // Start count at 1 not 0
                const objectKey = (index + 1) % 2 ? 'primary' : 'secondary';

                // Add to next available
                if (objectKey === 'primary') {
                    slides.push({
                        primary: null,
                        secondary: null
                    });
                }

                slides[slideIndex][objectKey] = item;

                // Next slide
                if (objectKey === 'secondary') slideIndex++;
            });

            return slides;
        }


        // Lifecycle
        componentDidMount() {
            if (this.slides.length > 1) {
                this.startAutoRotate();
            }
        }

        componentWillUnmount() {
            this.stopAutoRotate();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.items === nextProps.items) {
                this.stopAutoRotate();
                this.setState(WrapperComponent.createInitialState());
            }
        }


        // Helper
        isNext(index) {
            const { activeSlide, nextReady } = this.state;

            // If last image, first one becomes 'next' as well
            if (nextReady && index === 0 && activeSlide !== index) {
                return true;
            }

            return nextReady && ((activeSlide + 1 === index) || (activeSlide - 1 === index));
        }

        nextReadyAfterTime() {
            setTimeout(() => {
                this.setState({
                    nextReady: true
                });
            }, this.props.slideAnimationDuration);
        }

        startAutoRotate() {
            this.autoRotate = setInterval(() => {
                const shouldRotate = this.state.autoRotate;
                const isLastSlide = (this.state.activeSlide + 1 === this.slides.length);

                if (shouldRotate && isLastSlide) {
                    // Restart carousel
                    this.updateSlide(0);
                } else if (shouldRotate) {
                    this.nextSlide();
                } else {
                    // Stop the rotation
                    this.stopAutoRotate();
                }
            }, this.props.slideRotationAnimation);
        }

        stopAutoRotate() {
            clearInterval(this.autoRotate);
        }

        previousSlide() {
            let nextActiveSlide = Math.max(this.state.activeSlide - 1, 0);

            if (this.state.activeSlide === 0) {
                nextActiveSlide = this.slides.length - 1;
            }

            this.updateSlide(nextActiveSlide);
        }

        nextSlide() {
            let nextActiveSlide = Math.min(this.state.activeSlide + 1, this.slides.length - 1);

            if (this.state.activeSlide === this.slides.length - 1) {
                nextActiveSlide = 0;
            }

            this.updateSlide(nextActiveSlide);
        }

        updateSlide(slide = 0) {
            this.setState({
                activeSlide: slide,
                nextReady: false
            }, this.nextReadyAfterTime);
        }


        // Handler
        handleGoToSlide(slide) {
            this.updateSlide(slide);
        }

        handlePrevious() {
            this.setState({
                autoRotate: false
            }, this.previousSlide());
        }

        handleNext() {
            this.setState({
                autoRotate: false
            }, this.nextSlide());
        }


        // Render
        render() {
            const { activeSlide } = this.state;

            return (
                <WrappedComponent
                    activeSlide={activeSlide}
                    handleGoToSlide={this.handleGoToSlide.bind(this)}
                    handleNext={this.handleNext.bind(this)}
                    handlePrevious={this.handlePrevious.bind(this)}
                    isNext={this.isNext.bind(this)}
                    slides={this.slides}
                    {...this.props}
                />
            );
        }
    }

    WrapperComponent.propTypes = {
        items: PropTypes.array.isRequired,
        slideAnimationDuration: PropTypes.number,
        slideRotationAnimation: PropTypes.number
    };

    WrapperComponent.defaultProps = {
        slideAnimationDuration: 1000,
        slideRotationAnimation: 8000
    };

    return WrapperComponent;
}
