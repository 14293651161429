import { Map } from 'immutable';

export const INITIAL_STATE = new Map({
    isRouting: false
});

// Actions
export const STARTED_ROUTING = 'app/status/STARTED_ROUTING';
export const STOPPED_ROUTING = 'app/status/STOPPED_ROUTING';

// Action Creators
export const startedRoutingAction = () => ({ type: STARTED_ROUTING });
export const stoppedRoutingAction = () => ({ type: STOPPED_ROUTING });

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STARTED_ROUTING:
            return state.set('isRouting', true);
        case STOPPED_ROUTING:
            return state.set('isRouting', false);
        default:
            return state;
    }
};
