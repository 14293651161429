import PropTypes from 'prop-types';
import React from 'react';

/**
 * Specific component for Style Guide page to show swatch colours.
 * @returns {JSX} Swatch component
 */
function Swatch({ title, colour, variations, type = '' }) {
    return (
        <div className={`swatch colour-${colour} ${type}`}>
            <div className="swatch-inner">
                <p className="swatch-title">{title}</p>
                <div role="group" className="swatch-variations">
                    {variations && variations.map((variation, index) => {
                        return (<span key={index} className={`swatch-variation ${variation}`}></span>);
                    })}
                </div>
            </div>
        </div>
    );
}

Swatch.propTypes = {
    colour: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    variations: PropTypes.array
};

export default Swatch;
