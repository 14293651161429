import ImageGalleryDouble from 'app/components/partials/image-gallery-double';
import ImageGallerySingle from 'app/components/partials/image-gallery-single';
import PropTypes from 'prop-types';
import React from 'react';

const singleImageGalleryConfig = {
    slideAnimationDuration: 600,
    slideRotationAnimation: 9000
};

const doubleImageGalleryConfig = {
    slideAnimationDuration: 600,
    slideRotationAnimation: 12000
};

class ImageGallery extends React.Component {
    render() {
        const { items, pageTitle } = this.props;

        if (items.length % 2 === 0) {
            return (
                <section className="image-gallery">
                    <ImageGallerySingle items={items} {...singleImageGalleryConfig} pageTitle={pageTitle} />
                    <ImageGalleryDouble items={items} {...doubleImageGalleryConfig} pageTitle={pageTitle} />
                </section>
            );
        }

        return null;
    }
}

ImageGallery.propTypes = {
    items: PropTypes.array.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default ImageGallery;
