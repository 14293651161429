import ContentBlocks from 'app/components/content-blocks/index';
import HeroCarousel from 'app/components/partials/hero-carousel';
import HeroVideo from '../partials/hero-video';
import { HOME } from 'config/content';
import PropTypes from 'prop-types';
import React from 'react';

const heroCarouselConfig = {
    slideAnimationDuration: 1000,
    slideRotationAnimation: 6000
};

function Home(props) { // eslint-disable-line require-jsdoc
    const {
        blocks,
        name: title,
        hero_video: heroVideo,
        thumbnail: thumbnail,
    } = props.data;

    const heroIndex = blocks.findIndex((block) => block.type === 'herocarousel');
    let heroCarouselData = null;

    if (heroIndex >= 0) {
        heroCarouselData = blocks.splice(heroIndex, 1)[0].data;
    }

    return (
        <div className="content-page">
            {heroVideo && <HeroVideo heroVideo={heroVideo} thumbnail={thumbnail} />}
            {!heroVideo && <HeroCarousel items={heroCarouselData || HOME.HERO_CAROUSEL.items} {...heroCarouselConfig} />}
            <ContentBlocks data={blocks} pageTitle={title} />
        </div>
    );
}

Home.propTypes = {
    data: PropTypes.object.isRequired
};

export default Home;
