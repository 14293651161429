import AUCKLAND_UNLIMITED_LOGO from 'static/images/logos/auckland-unlimited-logo-white.svg';
import CollapsibleContent from 'app/components/partials/collapsible-content';
import Icon from 'app/components/partials/icon';
import TOITU_LOGO from 'static/images/logos/toitu-logo.svg';
import React from 'react';
import { SOCIAL_LINKS } from 'config/social-links.js';
import WomenRefuge from './women-refuge';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = Footer.createInitialState();
    }

    static createInitialState() {
        return {
            footerSectionOpen: null
        };
    }

    render() {
        const year = new Date().getFullYear();

        return (
            <footer className="footer">
                {/* Main */}
                <div className="footer-section || supplementary-color">
                    <div className="constrain-width large">

                        <div className="footer-main">
                            <div className="footer-main-column flex-logo">
                                <img className="logo" src={AUCKLAND_UNLIMITED_LOGO} alt="Tātaki Auckland Unlimited"/>
                                <img className="m-toitu-icon" src={TOITU_LOGO} alt="Toitū"/>
                            </div>

                            <div className="footer-main-column">
                                <CollapsibleContent title="Physical Address">
                                    <p>Level 4, Aotea Centre</p>
                                    <p>50 Mayoral Drive</p>
                                    <p>Auckland 1010</p>
                                </CollapsibleContent>
                            </div>
                            <div className="footer-main-column">
                                <CollapsibleContent title="Postal Address">
                                    <p>PO Box 5561</p>
                                    <p>Victoria Street West</p>
                                    <p>Auckland 1142</p>
                                </CollapsibleContent>
                            </div>
                            <div className="footer-main-column">
                                <CollapsibleContent title="Phone">
                                    <p><a href="tel:+6493092677">(09) 309 2677</a></p>
                                </CollapsibleContent>
                                <CollapsibleContent title="Follow us">
                                    <div className="social-links">
                                        {SOCIAL_LINKS.map(({ name, link }) => (
                                            <a className="social-links-item" href={link} key={name}>
                                                <Icon className="social-links-icon" name={name} />
                                            </a>
                                        ))}
                                    </div>
                                </CollapsibleContent>
                            </div>
                            <div className="footer-main-column toitu-icon">
                                <img src={TOITU_LOGO} alt="Toitū"/>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Outro */}
                <div className="footer-section padding-small">
                    <div className="constrain-width large">
                        <div className="footer-outro">
                            <p className="copyright">
                                <small>Copyright &copy; – Tātaki Auckland Unlimited {year}</small>
                                <span className="copyright-text-spacer">|</span>
                                <a className="link" href="/privacy-policy">Privacy policy</a>
                                <span className="copyright-text-spacer">|</span>
                                <a className="link" href="/terms-conditions">Terms and conditions</a>
                            </p>
                        </div>

                        <WomenRefuge />
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
