import carouselHOC from 'app/utilities/carousel-single.hoc';
import classnames from 'classnames';
import getThumbnailUrl from 'app/utilities/get-thumbnail-url';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ImageCarousel extends Component {
    renderNavigation() {
        const {
            activeSlide,
            handleNext,
            handleGoToSlide,
            handlePrevious,
            items,
            isNext
        } = this.props;

        // Only render pagination if there is more than one image
        if (items.length <= 1) return null;

        return (
            <div className="image-carousel-navigation">
                <div className="carousel-navigation">
                    {/* Background Images */}
                    <div className="carousel-navigation-background" aria-hidden="true">
                        {items.map(({ file }, index) => {
                            const itemClass = classnames('carousel-navigation-background-item', {
                                'is-active': (activeSlide === index),
                                'is-next': isNext(index)
                            });

                            return (
                                <div className={itemClass} style={{ backgroundImage: `url(${getThumbnailUrl(file)})` }} key={index}></div>
                            );
                        })}
                    </div>

                    {/* Actions */}
                    <nav className="carousel-navigation-actions">
                        {/* Previous */}
                        <button type="button" className="block-button overlay" onClick={handlePrevious.bind(this)}>
                            <span className="block-button-inner">
                                <Icon className="icon reverse" name="arrow-right" title="Previous slide"/>
                            </span>
                        </button>

                        {/* Pagination */}
                        <div className="carousel-pagination">
                            {items.map(({ title }, index) => {
                                const itemClass = classnames('carousel-pagination-item', {
                                    'is-active': (activeSlide === index),
                                    'is-next': isNext(index)
                                });

                                return (
                                    <button
                                        className={itemClass}
                                        title={title}
                                        onClick={handleGoToSlide.bind(this, index)}
                                        key={index}>
                                        <span className="carousel-pagination-item-title">{`Go to slide ${index}`}</span>
                                    </button>
                                );
                            })}
                        </div>

                        {/* Next */}
                        <button type="button" className="block-button overlay-dark" onClick={handleNext.bind(this)}>
                            <span className="block-button-inner">
                                <span className="block-button-label || image-carousel-next-action">
                                    {items.map(({ title }, index) => {
                                        let isActive = activeSlide === index - 1;

                                        if (activeSlide === items.length - 1 && index === 0) {
                                            isActive = 1;
                                        }

                                        const itemClass = classnames('image-carousel-next-action-item', {
                                            'is-active': isActive,
                                            'is-next': isNext(index - 1)
                                        });

                                        return (
                                            <span className={itemClass} key={index}>Next: {title}</span>
                                        );
                                    })}
                                </span>
                                <Icon name="arrow-right" title="Next slide" />
                            </span>
                        </button>
                    </nav>
                </div>
            </div>
        );
    }

    render() {
        const {
            activeSlide,
            items,
            isNext,
            pageTitle
        } = this.props;

        return (
            <section className="image-carousel">
                <div className="image-carousel-inner || constrain-width large">
                    {items[0].heading &&
                        <div className="image-carousel-header || constrain-width">
                            <h3>{items[0].heading}</h3>
                            <p>{items[0].subheading}</p>
                        </div>
                    }
                    {/* ImageCarousel */}
                    <div className="carousel">
                        <div className="carousel-inner || preserve-aspect-ratio">
                            {items.map(({ file, file_alt: fileAlt, link }, index) => {
                                const itemClass = classnames('carousel-media', {
                                    'is-active': (activeSlide === index),
                                    'is-next': isNext(index)
                                });

                                return (
                                    <a href={link} className={itemClass} key={`carousel-media-${index}`}>
                                        <div className="carousel-image || preserve-aspect-ratio-media" style={{ backgroundImage: `url(${file})` }}>
                                            <img className="fixed-image" src={file} alt={fileAlt || pageTitle} />
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                    </div>

                    {/* Navigation */}
                    {this.renderNavigation()}

                    {/* Content */}
                    <div className="image-carousel-content">
                        {items.map(({ title, subtitle, link }, index) => {
                            const itemClass = classnames('image-carousel-content-item', {
                                'is-active': (activeSlide === index),
                                'is-next': isNext(index)
                            });

                            return (
                                <div className={`${itemClass}  || constrain-width`} key={index}>
                                    <h4><a href={link}>{title}</a></h4>
                                    <p className="carousel-content-description">{subtitle}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

ImageCarousel.propTypes = {
    activeSlide: PropTypes.number.isRequired,
    handleGoToSlide: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    isNext: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default carouselHOC(ImageCarousel);
