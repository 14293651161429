/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import Header from './header';
import selectStatus from 'app/selectors/status';
import {
    fetchNotificationsAction,
    toggleNotificationsAction
} from 'app/ducks/notifications';
import {
    selectIsActive,
    selectNotifications
} from 'app/selectors/notifications';

function mapStateToProps(state) {
    return {
        notifications: selectNotifications(state),
        isNotificationsActive: selectIsActive(state),
        status: selectStatus(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNotifications: () => dispatch(fetchNotificationsAction()),
        toggleNotifications: () => dispatch(toggleNotificationsAction())
    };
}

const VisibleHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default VisibleHeader;
