/* eslint-disable require-jsdoc */

export function selectIsActive(state) {
    return state.notifications.isActive;
}

export function selectNotifications(state) {
    return state.notifications.notifications;
}

export function selectShowNotification(state) {
    return state.notifications.showNotification;
}
