/* eslint-disable no-console */

// The counter is used to create unique and sequential console grup names
let counter = 0;

export default (store) => (next) => (action) => {
    const groupName = `Dispatching (#${++counter})`;

    // Opening a console group.
    // The function does not exist in Node, hence the if-check
    if (console.groupCollapsed) console.groupCollapsed(groupName);

    // Logging pre-dispatch state
    console.log('Previous state', store.getState());

    // Logging the dispatched action/thunk/whatever
    console.info('Dispatched', action);

    // Passing the action down the pipeline
    const result = next(action);

    // Logging post-dispatch state
    console.log('Next state', store.getState());

    // Closing the console group.
    // The function does not exist in Node, hence the if-check
    if (console.groupEnd) console.groupEnd();

    return result;
};
