import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

function CallToAction(props) { // eslint-disable-line require-jsdoc
    const { data, pageTitle } = props;
    const {
        content,
        file,
        file_alt: fileAlt,
        content_position: contentPosition,
        padding_top: paddingTop,
        padding_bottom: paddingBottom,
    } = data;

    const mediaClass = classnames('call-to-action-media || preserve-aspect-ratio', {
        'left-aligned': contentPosition === 'right' // Aligns the media to the left position
    });

    const contentClass = classnames('call-to-action-content', {
        'right-aligned': contentPosition === 'right' // Aligns the content to the right position
    });

    const callToActionInnerClass = classnames('call-to-action-inner', {
        'padding-top': paddingTop === '1',
        'padding-bottom': paddingBottom === '1'
    });

    return (
        <section className="call-to-action">
            <div className={callToActionInnerClass}>
                <div className={mediaClass} style={{ backgroundImage: `url(${file})` }}>
                    <div className="preserve-aspect-ratio-media">
                        <img className="fixed-image" src={file} alt={fileAlt || pageTitle} />
                    </div>
                </div>
                <div className={contentClass}>
                    <div className="inner || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content, 'call-to-action') }}></div>
                </div>
            </div>
        </section>
    );
}

CallToAction.propTypes = {
    data: PropTypes.object.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default CallToAction;
