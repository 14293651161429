import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'app/utilities/truncate';

const TITLE_CHARACTER_LIMIT = 60;
const DESCRIPTION_CHARACTER_LIMIT = 80;

const SearchGrids = ({ items }) => {

    const getUrlByItem = (item) => {
        switch (item.type) {
            case 'news':
                return `/news/${item.attributes.slug}`;
            case 'pages':
                return item.attributes.parent ? `/${item.attributes.parent}/${item.attributes.slug}` : `/${item.attributes.slug}`;
            default:
                return null;
        }
    }

    const renderTile = (item, index) => {
        const {
            name,
            short_description: description,
            thumbnail,
            parent,
            slug,
        } = item.attributes;

        let style = {};

        if (thumbnail) {
            style = { backgroundImage: `url(${thumbnail})` };
        }

        const url =  getUrlByItem(item);

        return (
            <article className="search-grids-tile" key={index}>
                <div className="search-grids-tile-inner">
                    <Link to={url}>
                        <div className="tile-thumbnail" style={style}></div>
                        <h4 className="tile-title">{truncate(name, TITLE_CHARACTER_LIMIT)}</h4>
                    </Link>
                    <p className="tile-description">{truncate(description, DESCRIPTION_CHARACTER_LIMIT)}</p>
                </div>
            </article>
        );
    };

    return (
        <section>
            <div className="constrain-width large">
                <div className="tile-block-inner">
                    {items.map((item, index) => {
                        return renderTile(item, index);
                    })}
                </div>
            </div>
        </section>
    );
};

SearchGrids.propTypes = {
    items: PropTypes.array.isRequired,
};

export default SearchGrids;
