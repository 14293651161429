import carouselDoubleHOC from 'app/utilities/carousel-double.hoc';
import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import getThumbnailUrl from 'app/utilities/get-thumbnail-url';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

class ImageGalleryDouble extends React.Component {
    renderNext() {
        // Don't render navigation if less than 2 slides
        if (this.props.slides.length <= 1) return null;

        return (
            <button type="button" className="block-button overlay-dark" onClick={this.props.handleNext.bind(this)}>
                <span className="block-button-inner">
                    <Icon className="icon" name="arrow-right" title="Previous slide" />
                </span>
            </button>
        );
    }

    renderPrevious() {
        // Don't render navigation if less than 2 slides
        if (this.props.slides.length <= 1) return null;

        return (
            <button type="button" className="block-button overlay-dark" onClick={this.props.handlePrevious.bind(this)}>
                <span className="block-button-inner">
                    <Icon className="icon reverse" name="arrow-right" title="Next slide" />
                </span>
            </button>
        );
    }

    renderPagination() {
        const {
            activeSlide,
            handleGoToSlide,
            isNext,
            slides
        } = this.props;

        // Don't render pagiantion if less than 2 slides
        if (slides.length <= 1) return null;

        return (
            <div className="carousel-pagination">
                {slides.map(({ title }, index) => {
                    const itemClass = classnames('carousel-pagination-item', {
                        'is-active': (activeSlide === index),
                        'is-next': isNext(index)
                    });

                    return (
                        <button
                            className={itemClass}
                            title={title}
                            onClick={handleGoToSlide.bind(this, index)}
                            key={index}>
                            <span className="carousel-pagination-item-title">{`Go to slide ${index}`}</span>
                        </button>
                    );
                })}
            </div>
        );
    }

    render() {
        const { activeSlide, isNext, slides, pageTitle } = this.props;

        return (
            <section className="image-gallery-double">
                {/* Primary */}
                <div className="image-gallery-double-grid primary">

                    {/* Primary: Image */}
                    <div className="image-gallery-double-image primary || preserve-aspect-ratio standard">

                        {/* Primary: Carousel */}
                        <div className="carousel">
                            {slides.map(({ primary: item }, index) => { // eslint-disable-line
                                const { file, file_alt: fileAlt } = item;

                                const itemClass = classnames('carousel-media', {
                                    'is-active': activeSlide === index,
                                    'is-next': isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={index}>
                                        <div
                                            className="carousel-image || preserve-aspect-ratio-media || image-overlay"
                                            style={{ backgroundImage: `url(${file})` }}>
                                            <img className="fixed-image" src={file} alt={fileAlt || pageTitle} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Primary: Content */}
                    <div className="image-gallery-double-content">
                        {slides.map(({ primary: item }, index) => { // eslint-disable-line
                            const { creditline } = item;

                            const itemClass = classnames('image-gallery-double-content-item || rte-content || rich-editor-content', {
                                'is-active': activeSlide === index,
                                'is-next': isNext(index)
                            });

                            return (
                                <div
                                    className={itemClass}
                                    dangerouslySetInnerHTML={{ __html: formatContent(creditline) }}
                                    key={index}
                                ></div>
                            );
                        })}
                    </div>
                </div>

                {/* Secondary */}
                <div className="image-gallery-double-grid secondary">

                    {/* Content */}
                    <div className="image-gallery-double-content">

                        {/* Primary: Actions */}
                        <div className="image-gallery-double-actions">

                            {/* Primary: Background */}
                            <div className="carousel-navigation-background" aria-hidden="true">
                                {slides.map(({ primary: item }, index) => {
                                    const { file } = item;

                                    const itemClass = classnames('carousel-navigation-background-item', {
                                        'is-active': activeSlide === index,
                                        'is-next': isNext(index)
                                    });

                                    return (
                                        <div className={itemClass} style={{ backgroundImage: `url(${getThumbnailUrl(file)})` }} key={index}></div>
                                    );
                                })}
                            </div>

                            {this.renderPrevious()}
                        </div>

                        {/* Secondary: Content */}
                        <div className="image-gallery-double-content-inner">
                            {slides.map(({ secondary: item }, index) => { // eslint-disable-line
                                const { creditline } = item;

                                const itemClass = classnames('image-gallery-double-content-item || rte-content || rich-editor-content', {
                                    'is-active': activeSlide === index,
                                    'is-next': isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={index}>
                                        <div
                                            className={itemClass}
                                            dangerouslySetInnerHTML={{ __html: formatContent(creditline) }}
                                            key={index}
                                        ></div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Image */}
                    <div className="image-gallery-double-image primary || preserve-aspect-ratio standard">

                        {/* Carousel */}
                        <div className="carousel">

                            {/* Slides */}
                            {slides.map(({ secondary: item }, index) => {
                                const { file, file_alt: fileAlt } = item;

                                const itemClass = classnames('carousel-media', {
                                    'is-active': activeSlide === index,
                                    'is-next': isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={index}>
                                        <div
                                            className="carousel-image || preserve-aspect-ratio-media || image-overlay"
                                            style={{ backgroundImage: `url(${file})` }}>
                                            <img className="fixed-image" src={file} alt={fileAlt || pageTitle} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Actions */}
                        <div className="image-gallery-double-actions">

                            {/* Background */}
                            <div className="carousel-navigation-background" aria-hidden="true">
                                {slides.map(({ secondary: item }, index) => {
                                    const { file } = item;

                                    const itemClass = classnames('carousel-navigation-background-item', {
                                        'is-active': activeSlide === index,
                                        'is-next': isNext(index)
                                    });

                                    return (
                                        <div className={itemClass} style={{ backgroundImage: `url(${getThumbnailUrl(file)})` }} key={index}></div>
                                    );
                                })}
                            </div>

                            {this.renderPagination()}
                            {this.renderNext()}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

ImageGalleryDouble.propTypes = {
    activeSlide: PropTypes.number.isRequired,
    handleGoToSlide: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    slides: PropTypes.array.isRequired,
    isNext: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default carouselDoubleHOC(ImageGalleryDouble);
