/* eslint-disable dot-notation, no-multi-spaces */
import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

class Notification extends React.Component {
    render() {
        const {
            description,
            handleToggle,
            id,
            isVisible,
            title,
            type,
            url
        } = this.props;

        const notificationClass = classnames(`notification ${type}`, {
            'is-visible': isVisible,
        });

        return (
            <div className={notificationClass}>
                <div className="notification-inner || constrain-width x-large">
                    <Icon
                        className="notification-icon"
                        name={type}
                    />
                    <div className="notification-content">
                        <p className="notification-title">{title}</p>
                        {(url !== '' && description !== '') && <a href={url} className="link">{description}</a> ||
                        (description !== '') && <p>{description}</p>}
                    </div>
                    <button className="notification-toggle" onClick={() => handleToggle(id)}>
                        <Icon
                            className="notification-icon-close"
                            name="close"
                        />
                        Close
                    </button>
                </div>
            </div>
        );
    }
}

Notification.propTypes = {
    description: PropTypes.string.isRequired,
    handleToggle: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired, // one of NOTIFICATION_TYPES[x].className
    url: PropTypes.string.isRequired
};

export default Notification;
