import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function PagebreakQuote(props) { // eslint-disable-line require-jsdoc
    const {
        cite,
        file,
        quote,
        content_position: contentPosition,
        theme
    } = props;

    const pagebreakQuoteClass = classnames('pagebreak-quote', {
        'has-image': file,
        'plum': theme === 'plum',
        'coral': theme === 'coral',
        'navy': theme === 'navy'
    });

    const pagebreakMediaClass = classnames('pagebreak-quote-media || preserve-aspect-ratio', {
        'left-aligned': contentPosition === 'right' // Aligns the media to the left position
    });

    const pagebreakContentClass = classnames('pagebreak-quote-content', {
        'single': !file,
        'right-aligned': contentPosition === 'right' // Aligns the content to the right position
    });

    return (
        <section className={pagebreakQuoteClass}>
            <div className="constrain-width no-pad">
                <div className="pagebreak-quote-inner">
                    {file &&
                        <div className={pagebreakMediaClass} style={{ backgroundImage: `url(${file})` }}>
                            <div className="preserve-aspect-ratio-media">
                                <img className="fixed-image" src={file} alt={file} />
                            </div>
                        </div>
                    }
                    <div className={`${pagebreakContentClass} || constrain-width`}>
                        <blockquote>
                            <p>{quote}</p>
                            {cite && <em>{cite}</em>}
                        </blockquote>
                    </div>
                </div>
            </div>
        </section>
    );
}

PagebreakQuote.defaultProps = {
    file: null
};

PagebreakQuote.propTypes = {
    cite: PropTypes.string.isRequired,
    content_position: PropTypes.string.isRequired, // eslint-disable-line camelcase
    file: PropTypes.string,
    quote: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired
};

PagebreakQuote.defaultProps = {
    theme: 'blue'
};

export default PagebreakQuote;
