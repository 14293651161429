import PropTypes from 'prop-types';
import React from 'react';

function Loader({ type }) {
    return (
        <div className={`loader ${type ? type : ''}`}>
            <span className="loader-spinner || animate-spin"></span>
            <p className="loader-text">Loading..</p>
        </div>
    );
}

Loader.propTypes = {
    type: PropTypes.string
};

export default Loader;
