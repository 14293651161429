import Home from './home';
import News from './news';
import Page from './page';
import PropTypes from 'prop-types';
import React from 'react';

function Template({ data, type }) { // eslint-disable-line require-jsdoc
    switch (type) {
        case 'home':
            return <Home data={data} />;
        case 'news':
            return <News data={data} />;
        default:
            return <Page data={data} />;
    }
}

Template.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired
};

export default Template;
