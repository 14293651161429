/**
 * The function pushes data to the GTM data layer.
 * The dataLayer object is defined in the global scope by the GTM script.
 * @param {Object} data - Data layer record.
 */
export const dataLayerPush = (data) => {
    try {
        dataLayer.push({ // eslint-disable-line no-undef
            event: 'conversionTracking',
            ...data
        });
    } catch (e) {} // eslint-disable-line no-empty
};

/**
 * This fires the GTM event 'delayedHistoryChange'.
 * This event is used to update the data layer with the most recent page data.
 */
export const fireDelayedHistoryChange = () =>
    setTimeout(() =>
        dataLayerPush({
            event: 'delayedHistoryChange'
        }), 100); // eslint-disable-line no-magic-numbers
