import Helmet from 'react-helmet';
import React from 'react';

const Error = () => (
    <main className="page" role="main">
        <Helmet>
            <title>Error 500</title>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="error-page">
            <div className="error-page-inner || constrain-width medium">
                <div className="error-page-description">
                    <h1 className="heading-2">It looks like we have a server error</h1>
                    <p>
                    Our team is looking into it and we will fix it as soon as we can. <br className="breakline"/>
                    How can we help? Get in touch at <a href="mailto:info@aucklandunlimited.com">info@aucklandunlimited.com</a> or <span className="no-wrap">call <a href="tel:+6493092677">+64 9 309 2677</a></span>.
                    </p>
                </div>
            </div>
        </div>
    </main>
);

export default Error;
