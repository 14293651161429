/* eslint-disable camelcase, quotes */

// Hero Carousel
import HERO_IMAGE_1 from 'static/images/hero-taylor-swift.jpg';
import HERO_IMAGE_2 from 'static/images/hero-pou-rama.jpg';
import HERO_IMAGE_3 from 'static/images/hero-bug-lab.jpg';

export const HOME = {
    HERO_CAROUSEL: {
        items: [
            {
                title: 'Taylor Swift',
                subtitle: '9 November, Mt Smart Stadium',
                link: 'https://www.aucklandstadiums.co.nz/whats-on/taylor-swift',
                file: HERO_IMAGE_1
            },
            {
                title: 'Pou Rama',
                subtitle: 'Until 22 July, Aotea Square',
                link: 'https://www.aucklandlive.co.nz/show/pou-rama',
                file: HERO_IMAGE_2
            },
            {
                title: 'Bug Lab',
                subtitle: 'Until 26 August, Auckland Zoo',
                link: 'https://www.aucklandzoo.co.nz/bug-lab',
                file: HERO_IMAGE_3
            }
        ]
    }
};
