import ContentBlocks from 'app/components/content-blocks/index';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import React from 'react';

const Page = ({ data }) => {
    const {
        blocks,
        name: title,
        main_images: images,
        short_description: description
    } = data;

    return (
        <div className="content-page">
            <PageHeader
                images={images}
                introduction={description}
                title={title}
            />
            <ContentBlocks data={blocks} pageTitle={title} />
        </div>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired
};

export default Page;
