/* eslint-disable */
import Notification from './notification';
import PropTypes from 'prop-types';
import React from 'react';

class NotificationBanner extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggle = this.handleToggle.bind(this);

        this.state = {
            items: []
        };
    }


    // Lifecycle
    componentDidMount() {
        const banner = document.querySelector('.notification-banner');
        const main = document.querySelector('.main');

        banner.addEventListener('transitionend', () => {
            main.style.paddingTop = `${banner.offsetHeight}px`;
        }, false);
    }

    componentWillUnmount() {
        document.querySelector('.notification-banner').removeEventListener('transitionend');
    }

    // Handler
    handleToggle(id) {
        this.props.updateNotification(id);
    }

    // Render
    render() {
        const { isActive, notifications } = this.props;

        return (
            <div className={`notification-banner ${isActive ? '' : 'is-hidden'}`}>
                {notifications && notifications.map((item, index) => {
                    const {
                        description,
                        isPublished,
                        id,
                        title,
                        type,
                        url
                    } = item;

                    return (
                        <Notification
                            description={description}
                            id={id}
                            title={title}
                            type={type}
                            url={url}
                            handleToggle={this.handleToggle}
                            isVisible={isPublished}
                            key={index}
                        />
                    );
                })}
            </div>
        );
    }
}

NotificationBanner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    toggleNotifications: PropTypes.func.isRequired
};

export default NotificationBanner;
