/* eslint-disable camelcase */
import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const BUTTON_CLASS = 'button small';
const DESKTOP_LARGE_WIDTH = '1201';

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = { itemsOpen: null };
    }


    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setDefaultSelectedItem();
        }
    }

    componentDidMount() {
        // Binds the ondeviceorientation event listener to select default item when it is dektop size
        window.addEventListener('deviceorientation', this.setDefaultSelectedItem.bind(this));
        this.setDefaultSelectedItem();
    }


    // Helper
    setDefaultSelectedItem() {
        if (window.innerWidth < DESKTOP_LARGE_WIDTH) {
            this.setState({ itemsOpen: null });
        } else {
            this.setState({ itemsOpen: 0 });
        }
    }


    // Handler
    handleToggle(index) {
        // Prevent closing on desktop
        if (this.state.itemsOpen === index && window.innerWidth > DESKTOP_LARGE_WIDTH) return;

        this.setState({ itemsOpen: this.state.itemsOpen === index ? null : index });
    }


    // Render
    render() {
        const { itemsOpen } = this.state;
        const { items } = this.props;

        if (items.length < 1) {
            return null;
        }

        const heading = items[0].heading === '' ? null : items[0].heading;

        return (
            <section className="accordion">
                <div className="accordion-inner">
                    {heading &&
                        <div className="accordion-title || constrain-width">
                            <h3>{heading}</h3>
                        </div>
                    }
                    {items.map((item, index) => {
                        const { content, title } = item;
                        const isActiveItem = itemsOpen === index;
                        const itemClassName = 'accordion-item';
                        const itemClass = classnames(itemClassName, {
                            'is-active': isActiveItem
                        });

                        return (
                            <div className={itemClass} key={index}>
                                <button
                                    className={`${itemClassName}-header || constrain-width`}
                                    onClick={this.handleToggle.bind(this, index)}
                                >
                                    <h5 className={`${itemClassName}-title`}>{title}</h5>
                                    <div className="icon-button small">
                                        <Icon name="plus" />
                                    </div>
                                </button>
                                <div className={`${itemClassName}-content`}>
                                    <span className={`${itemClassName}-content-indicator || heading-3`} aria-hidden={true}>{`/ ${index + 1}`}</span>
                                    <div className={`${itemClassName}-content-wrapper`}>
                                        <div className={`${itemClassName}-content-container`}>
                                            <div className={`${itemClassName}-content-inner`}>
                                                <div
                                                    className={`${itemClassName}-content-description || constrain-width || rich-editor-content`}
                                                    dangerouslySetInnerHTML={{ __html: formatContent(content, null, BUTTON_CLASS) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        );
    }
}

Faq.propTypes = {
    items: PropTypes.array.isRequired
};

export default Faq;
