import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';

const NotFound = () => (
    <main className="page" role="main">
        <Helmet>
            <title>404 Page not found</title>
        </Helmet>
        <div className="error-page">
            <div className="error-page-inner || constrain-width medium">
                <div className="error-page-description">
                    <h1 className="heading-2">It looks like we have a broken link</h1>
                    <p>The page you are trying to access no longer exists.</p>
                    <Link className="button" to="/">To homepage</Link>
                </div>
            </div>
        </div>
    </main>
);

export default NotFound;
