import Loader from './loader';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Paginator = ({ children, currentItemsLength, btnClickHandler, total }) => {
    const [isLoading, setIsLoading] = useState(false);

    const loadMoreButtonClickHanlder = async() => {
        setIsLoading(true);
        try {
            await btnClickHandler();
        } finally {
            setIsLoading(false);
        }
    };

    const renderPaginationLinks = () => {
        return (
            <React.Fragment>
                <p>Showing {currentItemsLength} of {total} results</p>
                <div className="actions">
                    <button className="button primary" onClick={loadMoreButtonClickHanlder}>Load more</button>
                </div>
            </React.Fragment>
        );
    };


    return (
        <div className="pagination">
            {children}
            {isLoading && <Loader type="center-loader"/>}
            {!isLoading && currentItemsLength < total && renderPaginationLinks()}
        </div>
    );
};

Paginator.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    btnClickHandler: PropTypes.func.isRequired,
    currentItemsLength: PropTypes.number.isRequired,
    total: PropTypes.number
};

Paginator.default = {
    limit: 12
};

export default Paginator;
